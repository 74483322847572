import React, { useContext } from 'react'
import lodash from 'lodash'
import CartUI from './cart.component'
import { useLocation } from 'react-router-dom'
import { CartContext } from '../../context/cart-context'

const Cart = () => {
  const location = useLocation()
  const { cartItems } = useContext(CartContext)

  return (
    <CartUI
      name="CartSummary"
      data-pagename="main:consumer:gifts:home:"
      data-pagename2="main:consumer:gifts:home::::"
      isActive={location.pathname === '/cartsummary'}
      label={lodash.get(cartItems, 'length', 0)}
    />
  )
}

export default Cart
