import { Box } from '../box'
import styled from '@emotion/styled/macro'
import { ShoppingCartIcon } from '@paypalcorp/pp-react'

export const CartContainer = styled(Box)`
  z-index: 2;
  height: 100%;
`

export const CartBox = styled.div`
  display: flex;
  flex-direction: row;
  color: ${(props) =>
    props.isActive
      ? props.theme.colors.primary.black
      : props.theme.colors.secondary.greymedium};

  :hover {
    color: ${(props) => props.theme.colors.primary.black};
  }

  .ppvx_icon {
    color: currentColor;
    margin-right: 0;
  }

  p {
    transition: color 0.3s;
  }
`

export const CartIcon = styled(ShoppingCartIcon)`
  position: relative;
  padding-right: 3px;
  transition: color 0.3s;

  ::after {
    position: absolute;
    display: ${(props) => (props.label > 0 ? 'flex' : 'none')};
    flex-direction: column;
    justify-content: center;
    text-align: center;
    content: '${(props) => props.label}';
    font-family: 'PayPalSansBig-Medium', Helvetica Neue, Arial, sans-serif;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    background-color: ${(props) => props.theme.colors.primary.bluemedium};
    color: white;
    opacity: ${(props) => (props.label === 0 ? 0.6 : 1)};
    font-size: ${(props) => (props.label > 99 ? '.7rem' : '.8rem')};
    right: -2px;
    bottom: 0;
  }
`
